<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ $route.name }}</div>
      <div class="right" v-if="PERMISIONS.create">
        <div class="right" style="margin-top: 1.2rem">
          <div @click="createItem" class="Button1">
            <div class="icon">
              <img
                @load="imgLoaded"
                src="../assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content">
              Adaugă obiectiv
            </span>
          </div>
        </div>
      </div>
    </div>
    <template>
      <template v-if="PERMISIONS.list">
        <table-parent
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          :apiModuleName="'INSTITUTION_OBJECTIVES'"
          :tableMinimizeHead="true"
          :tableSpread="true"
          @Btn8="(i) => editStatus(i, true)"
          @Btn9="(i) => editStatus(i, false)"
          @totalRecords="(x) => (totalRecords = x)"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/institutionObiectivePermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import { INSTITUTION_OBJECTIVES } from "../api.js";
import DialogModal from "@/components/DialogModal";
import TableParent from "@/components/TableParent";

export default {
  name: "InstitutionObjectives",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  components: {
    TableParent,
  },
  data() {
    return {
      tableHead: [
        {
          title: "Nume",
          queryKey: "name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Data creării",
          queryKey: "createdAt",
          bottomHead: {
            quikFiltre: "date",
          },
        },
        {
          title: "Data dezactivării",
          queryKey: "disabledDate",
          bottomHead: {
            quikFiltre: "date",
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: "isActive",
          bottomHead: {
            iconsMap: [
              {
                tooltip: "Activat",
                icon: "fas fa-check-circle",
                color: "green",
              },
              {
                tooltip: "Dezactivat",
                icon: "fas fa-exclamation-circle",
                color: "red",
              },
            ],
            valuesMap: { value: [false, false] },
            queryMap: ["1", "0"],
          },
        },
      ],
      tableModule: INSTITUTION_OBJECTIVES.get,
      totalRecords: 0,
      reloadTable: 0,
    };
  },
  methods: {
    reload() {
      this.reloadTable++;
    },
    prepareData(row) {
      const preparation = [
        row.name || "",
        this.toDate(row.createdAt),
        row.disabledDate
          ? new Date(row.disabledDate).toLocaleString("ro-ro", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
            })
          : "-",
        {
          true: {
            border: "left",
            tooltip: "Activ",
            css: "font-size:2.2rem;color:green;",
            icon: "fas fa-check-circle",
          },
          false: {
            border: "left",
            tooltip: "Dezactivat",
            css: "font-size:2.2rem;color:red;",
            icon: "fas fa-exclamation-circle",
          },
        }[row.isActive] || "",
      ];

      const x = this.PERMISIONS;
      const prepareBtn = [];

      if (x.edit && !row.isActive) {
        prepareBtn.push([8, "Activează"]);
      }
      if (x.edit && row.isActive) {
        prepareBtn.push([9, "Dezactivează"]);
      }
      if (x.edit === true) {
        prepareBtn.push(2);
      }
      if (x.delete === true) {
        prepareBtn.push(3);
      }

      if (prepareBtn.length) {
        preparation.push([prepareBtn]);
      }

      return preparation;
    },
    createItem() {
      const name = {
        label: "Obiectiv",
        id: "name",
        type: "multy-inputs",
        componentProps: {
          minHeight: 200,
        },
        errReason: "Introduceți obiectivul.",
      };

      this.$modal.show(
        DialogModal,
        {
          target: "AddObjective",
          title: "Adaugă obiectiv",
          inputs: [name],
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Adaugă",
            handler: (sendData) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Adăugarea obiectivului a eșuat.");
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              INSTITUTION_OBJECTIVES.add(sendData)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Obiectivul a fost adăugat.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "AddObjective",
          adaptive: true,
          width: "650",
          height: "800",
        }
      );
    },
    editItem(data) {
      if (!Number.isInteger(data.id)) return;
      const name = {
        label: "Obiectiv",
        id: "name",
        type: "multy-inputs",
        componentProps: {
          minHeight: 200,
        },
        errReason: "Introduceți obiectivul.",
      };

      this.$modal.show(
        DialogModal,
        {
          target: "EditObjective",
          title: "Modifică obiectiv",
          inputs: [name],
          initialInputsData: data,
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Modifică",
            handler: (sendData) => {
              sendData = this.getEdited(sendData, data, true);

              const error = (msg) => {
                this.$toastr.e(msg || "Adăugarea obiectivului a eșuat.");
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              INSTITUTION_OBJECTIVES.edit(data.id, sendData)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Obiectivul a fost modificat.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "EditObjective",
          adaptive: true,
          width: "650",
          height: "800",
        }
      );
    },
    editStatus(data, isActive) {
      if (!Number.isInteger(data?.id)) return;

      this.$modal.show(
        DialogModal,
        {
          target: "EditStatusObjective",
          title: isActive ? "Activează" : "Dezactivează" + " obiectiv",
          content: `<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ${
            isActive ? "activezi" : "dezactivezi"
          } obiectivul selectat?</p></span>`,
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: isActive ? "Activează" : "Dezactivează",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(
                  msg || isActive
                    ? "Activarea"
                    : "Dezactivarea" + " obiectivului a eșuat."
                );
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              INSTITUTION_OBJECTIVES.changeStatus(data.id, {
                disabledDate: isActive ? null : new Date(),
                isActive,
              })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s(
                      `Obiectivul a fost ${isActive ? "activat" : "dezactivat"}`
                    );
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "EditStatusObjective",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );
    },
    deleteItem(data) {
      if (!Number.isInteger(data?.id)) return;

      this.$modal.show(
        DialogModal,
        {
          target: "DeleteObjective",
          title: "Ștergere obiectiv",
          content:
            '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ștergi obiectivul selectat?</p></span>',
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Șterge",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(msg || "Ștergerea obiectivului a eșuat.");
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              INSTITUTION_OBJECTIVES.delete(data.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Obiectivul a fost ștears.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "DeleteObjective",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );
    },
  },
  created() {
    if (!this.PERMISIONS.list) {
      return;
    }

    const canViewButtonsColumn = ["edit", "delete"];

    this.updateRoleStatus();
    if (this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      });
    }
  },
};
</script>
